<template>
  <div class="d-flex align-items-center">
    <div v-for="value in values" :key="value.id" class="mr-2 px-1 border-right">
      <b-form-group :label="value.label" class="d-flex justify-content-center">
        <b-form-checkbox v-model="value.value" :disabled="value.disabled" :value="value.id" :unchecked-value="null" />
      </b-form-group>
    </div>
    <div>
      <b-button variant="success" style="padding: 0.4rem" @click="updateFunction(data.notificationId, totalValues)">
        <feather-icon icon="SaveIcon" size="16" class="align-text-top mb-0" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox, BFormGroup, BButton } from "bootstrap-vue";

export default {
  components: { BFormCheckbox, BFormGroup, BButton },
  props: {
    data: {
      type: Object,
      required: true,
    },
    updateFunction: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      values: {
        sms: {
          label: "SMS",
          id: 2,
          disabled: false,
          value: null,
        },
        email: {
          label: "Email",
          id: 1,
          disabled: false,
          value: null,
        },
        inApp: {
          label: "In App",
          id: 4,
          disabled: false,
          value: null,
        },
      },
    };
  },
  computed: {
    totalValues() {
      return Object.values(this.values).reduce((acc, cur) => acc + cur.value, 0);
    },
  },
  watch: {
    data() {
      this.handleDisabledOptions();
      this.handlePermittedOptions();
    },
  },
  created() {
    this.handleDisabledOptions();
    this.handlePermittedOptions();
  },
  methods: {
    handleDisabledOptions() {
      const permitted = this.data.permittedChannelOption;
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.values) {
        if (Object.hasOwnProperty.call(this.values, key)) {
          const element = this.values[key];
          // eslint-disable-next-line no-bitwise
          if (permitted & element.id) {
            element.disabled = false;
          } else {
            element.disabled = true;
            element.value = false;
          }
        }
      }
    },
    handlePermittedOptions() {
      const current = this.data.currentChannelOption;
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.values) {
        if (Object.hasOwnProperty.call(this.values, key)) {
          const element = this.values[key];
          // eslint-disable-next-line no-bitwise
          if (!element.disabled && current & element.id) {
            element.value = element.id;
          }
        }
      }
    },
  },
};
</script>

<style></style>
